import React from "react";
import "./App.css";

function Discografia() {
  return (
    <div>
      {/* Header  */}
      <div className="row">
        <header className="header">
          <nav className="navbar navbar-expand-lg py-3 fixed-top">
            <div className="container">
              <div className="d-flex justify-content-center align-items-center">
                <a
                  className="nav-logo m-0 mx-0 me-lg-3 p-0 text-decoration-none me-md-3"
                  href="#">
                  HipHop
                </a>
                <img
                  src="./images/svg/cd.svg"
                  alt="cd"
                  id="cd"
                  className="m-0 px-0 d-none d-lg-block d-md-block d-sm-block"
                />
              </div>

              <button
                className=" navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span
                  className="navbar-toggler-icon rounded rounded-1 "
                  // style="background-color:#008aff;"
                ></span>
              </button>

              <div className="collapse navbar-collapse me-lg-3" id="navbarNav">
                <ul className="nav-ul navbar-nav mx-auto me-0 pe-3 align-items-md-center align-items-center">
                  <li className="mt-md-4 mt-sm-3 mt-4 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/home">
                      Inicio
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/videos">
                      Video clips
                    </a>
                  </li>
                  {/* <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/demos">
                      Demos
                    </a>
                  </li> */}
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/disc">
                      Discografia
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/bio">
                      Biografia
                    </a>
                  </li>
                  <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 mt-sm-3 nav-item">
                    <a
                      className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/eventos">
                      Eventos
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>

      {/* <!-- ? Contenido  --> */}

      <div className="container disc position-relative z-0">
        <section className="content-disc">
          <article className="tarject-disc  position-relative">
            <h1 className="text-center">La otra mitad</h1>

            <img
              src="./images/jpg/contraportada.jpg"
              className="img-disc"
              width={200}
              height={250}
            />

            <div className="dropdown" id="menu">
              <div className="dropdown__list">
                <a href="#" className="dropdown__link">
                  <img
                    src="./images/svg/cd.svg"
                    className="dropdown__icon"
                    width={50}
                  />
                  <span className="dropdown__span">Ver más</span>
                  <img
                    src="./images/svg/arrow.svg"
                    className="dropdown__arrow"
                    width="60"
                  />

                  <input type="checkbox" className="dropdown__check" />
                </a>

                <div className="dropdown__content">
                  <ul className="dropdown__sub">
                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Intro
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Desequilibrado
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Bombo Clap
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Reflexión
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Coctel de rimas
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Paz Interna
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Seamos amigos
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Nena
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Símbolo de lucha
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Nuestra gloria llegará
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </article>

          <article className="tarject-disc  position-relative">
            <h1>Orgánico</h1>

            <img
              src="./images/jpg/bio-grup.jpg"
              className="img-disc"
              width={200}
            />

            <div className="dropdown" id="menu">
              <div className="dropdown__list">
                <a href="#" className="dropdown__link">
                  <img
                    src="./images/svg/cd.svg"
                    className="dropdown__icon"
                    width={50}
                  />
                  <span className="dropdown__span">Ver más</span>
                  <img
                    src="./images/svg/arrow.svg"
                    className="dropdown__arrow"
                    width="60"
                  />

                  <input type="checkbox" className="dropdown__check" />
                </a>

                <div className="dropdown__content">
                  <ul className="dropdown__sub">
                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Soy una Droga
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Fantasy Activate
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        I see that will be
                      </p>
                    </li>

                    <li className="dropdown__li">
                      <p
                        // style="cursor: default;"
                        href="#"
                        className="dropdown__anchor">
                        Funk puro
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </article>
        </section>
      </div>

      {/* <!-- ? Pie --> */}

      <footer className="footer">
        <div className="container d-flex flex-column align-items-center p-3 pb-0">
          <h1 className="footer-title text-center">
            Escúchanos en <br /> <span className="">Plataformas</span>
          </h1>

          <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            <a href="https://www.youtube.com/@19117records">
              <img
                src="./images/svg/youtube-f-w.svg"
                alt="YouTube"
                width="170"
                title="YouTube"
                // style="margin-top: -40px; margin-right: -30px;"
                id="img-yt"
              />
            </a>
            <a href="https://music.youtube.com/channel/UC10vrCHWlaqmeQIz8NmFW2Q?si=8MPL4BkImfI_TOZQ">
              <img
                src="./images/svg/yt_music-f-w.svg"
                alt="YouTube Music"
                width="80"
                title="YouTube Music"
              />
            </a>
            <a href="https://open.spotify.com/intl-es/album/7wdu2FFbR8uHdB3d2uPVJu?si=zWuRTt9QRSCVh0Yf1yeEDg">
              <img
                src="./images/svg/spotify-f-w.svg"
                alt="Spotify"
                width="80"
                title="Spotify"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/deezer-f-w.svg"
                alt="Dezzer"
                width="80"
                title="Deezer"
              />
            </a>
          </div>

          <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div className="footer-contact text-center">
              <h2>Contacto</h2>
              <p>johnatanmenasalcedo@gmail.com</p>
              <p>3146148505</p>
            </div>
          </div>
        </div>

        <div className="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; TURKANO | OG CARE-K</p>
          <p className="otto text-uppercase">&copy; OTTO | Carlos Arteaga</p>
        </div>
      </footer>
    </div>
  );
}

export default Discografia;
