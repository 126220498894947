import React from "react";
import "./App.css";

function Videos() {
  return (
    <div>
      {/* ? Header  */}
      <div class="row">
        <header class="header">
          <nav class="navbar navbar-expand-lg py-3 fixed-top">
            <div class="container">
              <div class="d-flex justify-content-center align-items-center">
                <a
                  class="nav-logo m-0 mx-0 me-lg-3 p-0 text-decoration-none me-md-3"
                  href="#">
                  HipHop
                </a>
                <img
                  src="./images/svg/cd.svg"
                  alt="cd"
                  id="cd"
                  class="m-0 px-0 d-none d-lg-block d-md-block d-sm-block"
                />
              </div>

              <button
                class=" navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon rounded rounded-1 "></span>
              </button>

              <div class="collapse navbar-collapse me-lg-3" id="navbarNav">
                <ul class="nav-ul navbar-nav mx-auto me-0 pe-3 align-items-md-center align-items-center">
                  <li class="mt-md-4 mt-sm-3 mt-4 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/home">
                      Inicio
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/videos">
                      Video clips
                    </a>
                  </li>
                  {/* <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/demos">
                      Demos
                    </a>
                  </li> */}
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/disc">
                      Discografía
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/bio">
                      Biografia
                    </a>
                  </li>
                  <li class="mt-md-3 mt-sm-3 mt-3 m-lg-0 mt-sm-3 nav-item">
                    <a
                      class="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                      aria-current="page"
                      href="/eventos">
                      Eventos
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </div>

      {/* ? Contrenido  */}

      <div class="container content-v h-100 w-75 my-5 py-5">
        <div class="title">
          <h1 class="text-center pt-4">
            Video Clips con mucho FLOW <br /> <span>OG Care-K y Turkano</span>
          </h1>
        </div>

        <div class="content-videos row">
          {/* ! Carek */}
          <div class="left">
            <div class="card card-1">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/AhhMznQO0l4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>

            <div class="card card-2">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/wtORlitXNDg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>

            <div class="card card-3">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/5KkGZuByixo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>

            <div class="card card-4">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/3nsM_Ja_B3E"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>

          {/* ! Mena */}
          <div class="rigth">
            <div class="card card-1">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/0QnLauPF83g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>

            <div class="card card-2">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/p4tmTX1lgS4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>

            <div class="card card-3">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/CrFIFawM4XM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>

            <div class="card card-4">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/s2KBN14sjUk"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>

      {/* ? Pie  */}

      <footer class="footer">
        <div class="container d-flex flex-column align-items-center p-3 pb-0">
          <h1 class="footer-title text-center">
            Escúchanos en <br /> <span class="">Plataformas</span>
          </h1>

          <div class="footer-imgs d-flex justify-content-around w-75 mt-4">
            <a href="https://www.youtube.com/@19117records">
              <img
                src="./images/svg/youtube-f-w.svg"
                alt="YouTube"
                width="170"
                title="YouTube"
                // style="margin-top: -40px; margin-right: -30px;"
                id="img-yt"
              />
            </a>
            <a href="https://music.youtube.com/channel/UC10vrCHWlaqmeQIz8NmFW2Q?si=8MPL4BkImfI_TOZQ">
              <img
                src="./images/svg/yt_music-f-w.svg"
                alt="YouTube Music"
                width="80"
                title="YouTube Music"
              />
            </a>
            <a href="https://open.spotify.com/intl-es/album/7wdu2FFbR8uHdB3d2uPVJu?si=zWuRTt9QRSCVh0Yf1yeEDg">
              <img
                src="./images/svg/spotify-f-w.svg"
                alt="Spotify"
                width="80"
                title="Spotify"
              />
            </a>
            <a href="#">
              <img
                src="./images/svg/deezer-f-w.svg"
                alt="Dezzer"
                width="80"
                title="Deezer"
              />
            </a>
          </div>

          <div class="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div class="footer-contact text-center">
              <h2>Contacto</h2>
              <p>johnatanmenasalcedo@gmail.com</p>
              <p>3146148505</p>
            </div>
          </div>
        </div>

        <div class="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; TURKANO | OG CARE-K</p>
          <p class="otto text-uppercase">&copy; OTTO | Carlos Arteaga</p>
        </div>
      </footer>
    </div>
  );
}

export default Videos;
