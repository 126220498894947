import React from "react";
import "./App.css";

function Home() {
  return (
    <div>
      <div className="container">
        {/* ? Header */}
        <div className="row">
          <header className="header">
            <nav className="navbar navbar-expand-lg py-3 fixed-top">
              <div className="container">
                <div className="d-flex justify-content-center align-items-center">
                  <a
                    className="nav-logo m-0 mx-0 me-lg-3 p-0 text-decoration-none me-md-3"
                    href="/home">
                    HipHop
                  </a>
                  <img
                    src="./images/svg/cd.svg"
                    alt="cd"
                    id="cd"
                    className="m-0 px-0 d-none d-lg-block d-md-block d-sm-block"
                  />
                </div>

                <button
                  className=" navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon rounded rounded-1 "></span>
                </button>

                <div
                  className="collapse navbar-collapse me-lg-3"
                  id="navbarNav">
                  <ul className="nav-ul navbar-nav mx-auto me-0 pe-3 align-items-md-center align-items-center">
                    <li className="mt-md-4 mt-sm-3 mt-4 m-lg-0 nav-item">
                      <a
                        className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                        aria-current="page"
                        href="/home">
                        Inicio
                      </a>
                    </li>
                    <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                      <a
                        className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                        aria-current="page"
                        href="/videos">
                        Video clips
                      </a>
                    </li>
                    {/* <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                      <a
                        className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                        aria-current="page"
                        href="/demos">
                        Demos
                      </a>
                    </li> */}
                    <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                      <a
                        className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                        aria-current="page"
                        href="/disc">
                        Discografia
                      </a>
                    </li>
                    <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 nav-item">
                      <a
                        className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                        aria-current="page"
                        href="/bio">
                        Biografia
                      </a>
                    </li>
                    <li className="mt-md-3 mt-sm-3 mt-3 m-lg-0 mt-sm-3 nav-item">
                      <a
                        className="px-lg-4 px-md-5 px-sm-5 px-5 nav-link"
                        aria-current="page"
                        href="/eventos">
                        Eventos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        </div>

        {/* </div> ? Banner */}
        <div>
          <article className="banner">
            <div id="container-tur">
              <h1 className="banner-title banner-title_turkan d-lg-inline-block d-md-inline-block d-sm-inline-block d-inline-block">
                Turkano
              </h1>
            </div>

            <div id="container-og">
              <h1 className="banner-title banner-title_og d-lg-inline-block d-md-inline-block d-sm-inline-block d-inline-block">
                Og Care-k
              </h1>
            </div>
          </article>
        </div>
      </div>

      {/* ? Separador  */}
      <div className="separador w-100"></div>

      {/* Contenido  */}
      <div className="container">
        <section className="info mt-3 ">
          <div className="caja caja1">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/vlwoOhPeBvI"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>

          <div className="caja caja2">
            <h1 className="text-uppercase">
              Nuestros
              <br />
              <span className="fs-2">video</span>
              <br />
              clips
            </h1>
            <a
              href="#"
              className="px-4 py-2 text-decoration-none fs-5 rounded-1 d-sm-none d-none d-lg-inline-block">
              Ver más
            </a>
          </div>

          <div id="separador-cajas" className="d-block d-lg-none"></div>

          <div className="caja caja3">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/OsJEumhTbgA"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>

          <div
            id="view-box"
            className="text-center my-3 d-lg-none d-sm-inline-block">
            <a
              href="#"
              className="d-inline-block d-lg-none px-4 py-2 text-decoration-none fs-5 rounded-1">
              Ver más
            </a>
          </div>
        </section>

        <section className="content my-5 my-sm-3">
          <article className="events">
            <div className="events-header py-3">
              <h1>
                Proximos <br /> Eventos
              </h1>
              <a
                href="#"
                className="px-4 px-sm-3 py-2 text-decoration-none fs-5 rounded-1">
                Ver más
              </a>
            </div>

            <div className="events-body py-4">
              <p className="fs-4 ps-2">
                Medellin
                <br /> 07/Febrero/2025 <br />
                Itagüi 
              </p>
              <div>
                <a
                  href="#"
                  className="botonv me-2 px-4 px-sm-3 py-2 text-decoration-none fs-5 rounded-1 d-inline-block">
                  Ver más
                </a>
                <a
                  href="#"
                  className="px-4 px-sm-3 py-2 text-decoration-none fs-5 rounded-1 d-inline-block">
                  Comprar tickets
                </a>
              </div>
            </div>
          </article>

          <aside className="social">
            <div className="social-header">
              <h5 className="ps-4 py-3">Redes Sociales Turkano</h5>
            </div>

            <div id="social-turkano" className="social-body ps-4 my-4">
              <a href="https://www.facebook.com/share/1AicoMeRSk/?mibextid=wwXIfr" className="social-body_icon" target="_blank">
                <img
                  src="./images/svg/facebook.svg"
                  alt="Facebook"
                  width="60"
                />
                <p className="overly px-3">Facebook</p>
              </a>
              <a href="https://www.instagram.com/tur_kano/" className="social-body_icon" target="_blank">
                <img
                  src="./images/svg/instagram.svg"
                  alt="Instagram"
                  width="60"
                />
                <p className="overly px-3">Instagram</p>
              </a>
              <a
                href="https://www.youtube.com/@19117records"
                className="social-body_icon"
                target="_blank">
                <img src="./images/svg/youtube.svg" alt="YouTube" width="60" />
                <p className="overly px-3">YouTube</p>
              </a>
            </div>

            <div className="social-separador"></div>

            <div className="social-header">
              <h5 className="ps-4 py-3">Redes Sociales OG Care-k</h5>
            </div>

            <div id="social-og" className="social-body ps-4 my-4">
              <a
                href="https://www.facebook.com/carlos.tellezposada?mibextid=ZbWKwL"
                className="social-body_icon"
                target="_blank">
                <img
                  src="./images/svg/facebook.svg"
                  alt="Facebook"
                  width="60"
                />
                <p className="overly px-3">Facebook</p>
              </a>
              <a
                href="https://www.instagram.com/carek.xtyle/?igshid=NjIwNzIyMDk2Mg%3D%3D"
                className="social-body_icon"
                target="_blank">
                <img
                  src="./images/svg/instagram.svg"
                  alt="Instagram"
                  width="60"
                />
                <p className="overly px-3">Instagram</p>
              </a>
              <a href="#" className="social-body_icon" target="_blank">
                <img src="./images/svg/youtube.svg" alt="YouTube" width="60" />
                <p className="overly px-3">YouTube</p>
              </a>
            </div>
          </aside>
        </section>
      </div>

      {/* ? Footer */}

      <footer className="footer">
        <div className="container d-flex flex-column align-items-center p-3 pb-0">
          <h1 className="footer-title text-center">
            Escúchanos en
            <br />
            <span>Plataformas</span>
          </h1>

          <div className="footer-imgs d-flex justify-content-around w-75 mt-4">
            <a href="https://www.youtube.com/@19117records">
              <img
                src="./images/svg/youtube-f-w.svg"
                alt="YouTube"
                width="170"
                title="YouTube"
                // style="margin-top: -40px; margin-right: -30px;"
                id="img-yt"
              />
            </a>
            <a href="https://music.youtube.com/channel/UC10vrCHWlaqmeQIz8NmFW2Q?si=8MPL4BkImfI_TOZQ">
              <img
                src="./images/svg/yt_music-f-w.svg"
                alt="YouTube Music"
                width="80"
                title="YouTube Music"
              />
            </a>
            <a href="https://open.spotify.com/intl-es/album/7wdu2FFbR8uHdB3d2uPVJu?si=zWuRTt9QRSCVh0Yf1yeEDg">
              <img
                src="./images/svg/spotify-f-w.svg"
                alt="Spotify"
                width="80"
                title="Spotify"
              />
            </a>
            <a href="www.youtube.com/channel/UC10vrCHWlaqmeQIz8NmFW2Q">
              <img
                src="./images/svg/deezer-f-w.svg"
                alt="Dezzer"
                width="80"
                title="Deezer"
              />
            </a>
          </div>

          <div className="comunication  w-50 d-lg-flex justify-content-around align-items-center py-3 pb-5 d-grid col-1 ">
            <div className="footer-contact text-center">
              <h2>Contacto</h2>
              <p>johnatanmenasalcedo@gmail.com</p>
              <p>3146148505</p>
            </div>
          </div>
        </div>

        <div className="copy d-flex justify-content-between mx-3 fs-6">
          <p>2023, &copy; TURKANO | OG CARE-K</p>
          <p className="otto text-uppercase">&copy; OTTO | Carlos Arteaga</p>
        </div>
      </footer>

      <script src="./css/bootstrap/js/bootstrap.min.js"></script>
      <script src="./jquery/jquery-3.7.0.min.js"></script>
    </div>
  );
}

export default Home;
